@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap';
@import 'app/_shared/styles/colors';

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: $color-gray-bg;
  color: $color-txt-gray;
  overflow-x: hidden;
}

*:focus {
  outline: none !important;
}

.pink-underline {
  border-bottom: 1px solid $color-pink;
}

.mat-fab.mat-accent, .mat-flat-button.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent {
  background-color: $color-pink;
}

.clickable {
  cursor: pointer;
}

.mini-loader {
  width: 100%;

  img {
    max-width: 100px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.table-full {
  margin: 10px;
  width: calc(100% - 20px)
}

.hero {
  padding: 10px;
  border-bottom: $color-gray-bg 1px solid;
  margin-bottom: 10px;
}

.clear {
  clear: both;
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}
