$color-pink: #ff0066;
$color-gray-bg: #e4e4e4;
$color-gray-dark: #323232;

$color-txt-gray: #3f3f3f;
$color-txt-gray-light: #858585;

.txt-pink {
  color: $color-pink
}
.txt-gray {
  color: $color-txt-gray
}
.txt-gray-light {
  color: $color-txt-gray-light
}
